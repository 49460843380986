import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledPickerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 575px) {
    width: 100%;

    .rdrDateRangePickerWrapper {
      display: block;
    }
  }
`;

export const StyledPickerPopover = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 11px);
  z-index: 3;
  padding: 20px 20px 10px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    padding: 30px 40px 10px;
  }

  @media screen and (max-width: 575px) {
    left: 0;
  }
`;

export const StyledPickerButton = styled.button`
  position: relative;
  padding-right: 90px;
  font-size: ${themeGet('fontSizes.xs')}px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.text.default')};
  line-height: 2;

  .dropdown-icon {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const StyledPicker = styled.div`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.text.default')};

  > .calendar-icon {
    margin-right: 13px;
  }

  &.open {
    ${StyledPickerButton} {
      .dropdown-icon {
        top: 40%;
        transform: rotate(180deg);
      }
    }
  }
`;
