import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';

import Loader from 'components/common/loader/loader';
import GridTable from 'components/grid-table/grid-table';
import { useLuck } from 'contexts/luck.context';

import { GET_LUCK_STATISTICS } from 'graphql/luck.query';
import { ISOString } from 'utils/custom-static-ranges';

const LuckGridTable = () => {
  const { activeStateRange, activeCoin } = useLuck();

  const columnsLuck = [
    {
      title: 'gridTable.date',
      dataIndex: 'dateTime'
    },
    {
      title: 'gridTable.amount',
      preTitle: activeCoin?.tag.toUpperCase(),
      dataIndex: 'reward'
    },
    {
      title: 'gridTable.hashrate',
      dataIndex: 'hashRate'
    },
    {
      title: `${activeCoin?.tag.toUpperCase()} / THS`,
      dataIndex: 'expectedReward'
    },
    {
      title: `${activeCoin?.tag.toUpperCase()} / THS`,
      subtitle: 'WhatToMine.com',
      dataIndex: 'networkExpectedReward'
    },
    {
      title: 'gridTable.luck',
      dataIndex: 'luck'
    }
  ];

  const [loadLuckStatistics, { data, loading }] =
    useLazyQuery(GET_LUCK_STATISTICS);

  useEffect(() => {
    if (activeCoin?.id) {
      loadLuckStatistics({
        variables: {
          coinId: activeCoin?.id,
          fromDate: format(activeStateRange.startDate, ISOString),
          toDate: format(activeStateRange.endDate, ISOString)
        }
      });
    }
  }, [
    activeCoin?.id,
    activeStateRange.endDate,
    activeStateRange.startDate,
    loadLuckStatistics
  ]);

  if (loading) return <Loader width="30px" height="30px" />;

  return <GridTable columns={columnsLuck} data={data?.luckStatistics} tfoot />;
};

export default LuckGridTable;
