import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import { LuckContextProvider } from 'contexts/luck.context';
import DatePicker from 'components/date-range-picker/date-range-picker';
import Heading from 'components/common/heading/heading';
import LuckStatistics from 'components/luck-statistics/luck-statistics';
import LuckGridTable from 'components/luck-statistics/luck-grid-table/luck-grid-table';

import { MainContentArea, Container, Row } from 'assets/styles/pages.style';

export default function LuckPage() {
  const { t } = useTranslation();

  return (
    <LuckContextProvider>
      <MainContentArea pt={[4, 5]}>
        <Container>
          <Row mb={[6, 7]}>
            <LuckStatistics />
          </Row>
          <Row px={[0, 4]}>
            <Heading
              mb={4}
              justifyContent="space-between"
              variant="h3"
              component={<DatePicker />}
            >
              {t('luckPeriodBlock.title')}
            </Heading>
            <LuckGridTable />
          </Row>
        </Container>
      </MainContentArea>
    </LuckContextProvider>
  );
}

export function Head() {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.luck.title')} />;
}
