import styled from 'styled-components';
import css from '@styled-system/css';
import { themeGet } from '@styled-system/theme-get';

export const StyledRangePicker = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 34px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const StyledButton = styled.button((props) =>
  css({
    px: '24px',
    py: '14px',
    borderRadius: 'small',
    fontWeight: 'button',
    fontSize: 'base',
    color: props.active ? '#fff' : 'text.default',
    bg: props.active ? 'primary.regular' : 'transparent',
    boxShadow: props.active ? 'button' : 'none',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    lineHeight: '1.5',
    whiteSpace: 'nowrap'
  })
);
