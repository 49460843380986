import { useCoins } from 'contexts/coins.context';
import { getComponentIcon } from 'utils/constant';
import { Button } from './toggler-button';
import { ButtonWrapper } from './coin-toggler.style';

const CoinItem = ({ item, state }) => {
  const [activeValue, onClickHandler] = state;

  return (
    <Button
      onClick={() => onClickHandler(item)}
      active={activeValue?.id === item?.id}
    >
      {getComponentIcon(item?.tag)}
      {item?.tag}
    </Button>
  );
};

export const CoinCustomToggler = ({ width, state, coins = [] }) => {
  if (coins.length < 2) return null;

  return (
    <ButtonWrapper className="coin-toggler" width={width}>
      {coins.map((item) => (
        <CoinItem key={item.value} item={item} state={state} />
      ))}
    </ButtonWrapper>
  );
};

const CoinToggler = ({ width, state }) => {
  const { coins } = useCoins();

  if (coins.length === 0) return null;

  return (
    <ButtonWrapper className="coin-toggler" width={width}>
      {coins.map((item) => (
        <CoinItem key={item.id} item={item} state={state} />
      ))}
    </ButtonWrapper>
  );
};

export default CoinToggler;
