import styled from 'styled-components';
import css from '@styled-system/css';

export const Wrapper = styled.div(() =>
  css({
    display: 'grid',
    gridGap: ['20px', '40px'],
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)']
  })
);
