import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { addDays, format, startOfDay } from 'date-fns';
import * as locales from 'react-date-range/dist/locale';
import cx from 'classnames';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import CalendarIcon from 'components/icons/CalendarIcon';
import DropArrowIcon from 'components/icons/DropArrowIcon';

import { useLuck } from 'contexts/luck.context';
import { useTheme } from 'contexts/theme.context';
import { Button } from 'components/common/button/button';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { lastWeek } from 'utils/custom-static-ranges';
import useOnClickOutside from 'utils/hooks/use-on-click-outside';

import { DateRangeStyles } from './date-range-picker-css';

import {
  StyledPicker,
  StyledPickerButton,
  StyledPickerPopover,
  StyledPickerWrapper
} from './date-range-picker.style';

const initialRange = {
  startDate: lastWeek.range().startDate,
  endDate: startOfDay(addDays(lastWeek.range().endDate, -1)),
  key: 'selection'
};

const formateOutputDateRange = (obj) => {
  return `${format(obj.startDate, 'yyyy-MM-dd')} / ${format(
    obj.endDate,
    'yyyy-MM-dd'
  )}`;
};

const DatePicker = () => {
  const { t } = useTranslation();
  const { language } = useTheme();
  const { activeDateRange, toggleStateRange, toggleRange } = useLuck();
  const { width } = useWindowSize();
  const dropdownRef = useRef();
  const [isShowDatePicker, setShowDatePicker] = useState(false);
  const [stateRange, setStateRange] = useState(initialRange);
  const direction = width < 767 ? 'vertical' : 'horizontal';

  const [locale, setLocale] = useState('enUS');

  useEffect(() => {
    if (language === 'ua') {
      setLocale('uk');
    }
    if (language === 'en') {
      setLocale('enUS');
    }
    if (language === 'cn') {
      setLocale('zhTW');
    }
  }, [language]);

  useOnClickOutside(dropdownRef, () => setShowDatePicker(false));

  useEffect(() => {
    toggleRange(activeDateRange);
  }, [activeDateRange]);

  const onSubmitHandler = () => {
    toggleStateRange({
      key: 'selection',
      startDate: stateRange.startDate,
      endDate: addDays(new Date(stateRange.endDate), 1)
    });
    setShowDatePicker(false);
  };

  return (
    <StyledPickerWrapper>
      <StyledPicker
        className={cx({
          open: isShowDatePicker
        })}
        onClick={() => setShowDatePicker((isShow) => !isShow)}
      >
        <CalendarIcon />
        <StyledPickerButton>
          {formateOutputDateRange(stateRange)}
          <DropArrowIcon color="#c1ccec" />
        </StyledPickerButton>
      </StyledPicker>

      {isShowDatePicker && (
        <StyledPickerPopover ref={dropdownRef}>
          <DateRange
            onChange={(item) => setStateRange(item.selection)}
            showSelectionPreview
            showMonthAndYearPickers
            showMonthArrow
            weekdayDisplayFormat="eeeeee"
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[stateRange]}
            direction={direction}
            color="#6487ff"
            key="selection"
            showDateDisplay={false}
            minDate={new Date(2020, 6, 1)}
            maxDate={new Date()}
            locale={locales[locale]}
          />
          <Button onClick={onSubmitHandler} fullwidth>
            {t('datePicker.buttonI18n')}
          </Button>
        </StyledPickerPopover>
      )}

      <DateRangeStyles />
    </StyledPickerWrapper>
  );
};

export default DatePicker;
