import RangeButton from './range-button';
import { StyledRangePicker } from './range-picker.style';

const rangesData = [
  {
    id: 'staticDataRangePicker.todayI18n',
    index: 'today'
  },
  {
    id: 'staticDataRangePicker.yesterdayI18n',
    index: 'yesterday'
  },
  {
    id: 'staticDataRangePicker.lastWeekI18n',
    index: 'lastWeek'
  },
  {
    id: 'staticDataRangePicker.lastMonthI18n',
    index: 'lastMonth'
  }
];

const RangePicker = () => {
  return (
    <StyledRangePicker>
      {rangesData.map((item) => (
        <RangeButton key={item.id} item={item} />
      ))}
    </StyledRangePicker>
  );
};

export default RangePicker;
