import { gql } from '@apollo/client';

export const GET_LUCK_STATISTICS = gql`
  query ($coinId: ObjectID!, $fromDate: Time!, $toDate: Time!) {
    luckStatistics(coinId: $coinId, fromDate: $fromDate, toDate: $toDate) {
      blockCount
      reward
      expectedReward
      networkExpectedReward
      hashRate
      luck
      prices
      dateTime
    }
  }
`;
