import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledButton = styled.button((props) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: '25px',
    py: '14px',
    cursor: props.active ? 'default' : 'pointer',
    color: props.active ? '#fff' : 'primary.regular',
    bg: props.active ? 'primary.regular' : 'primary.light',
    boxShadow: props.active ? 'button' : '',
    transition: 'all 0.3s ease',
    borderRadius: 'small',
    fontSize: 'base',

    '> svg': {
      marginRight: '10px'
    },

    '&:focus': {
      outline: 'none'
    },

    '&:hover': {
      bg: props.active ? '' : 'button.hover',
      color: props.active ? '' : '#fff'
    }
  })
);

// TODO: move spinner from src/button into separated component and reuse it here

export const Button = ({ children, active, ...rest }) => (
  <StyledButton active={active} {...rest}>
    {children}
    {/* {loading && <Spinner />} */}
  </StyledButton>
);
