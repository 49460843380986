import {
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useContext
} from 'react';

import { useCoins } from 'contexts/coins.context';
import customStaticRanges, { lastWeek } from 'utils/custom-static-ranges';

const initialState = {
  activeCoin: null,
  activeDateRange: 'lastWeek',
  activeStateRange: lastWeek.range()
};

const LuckContext = createContext();

LuckContext.displayName = 'LuckContext';

const luckReducer = (s, v) => ({ ...s, ...v });

export const LuckContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(luckReducer, initialState);
  const { activeCoin } = state;
  const { coins } = useCoins();

  useEffect(() => {
    if (activeCoin === null && coins.length > 0) {
      toggleCoin(coins[0]);
    }
  }, [activeCoin, coins]);

  const toggleCoin = (coin) => dispatch({ activeCoin: coin });
  const toggleDateRange = (activeDateRange) => dispatch({ activeDateRange });
  const toggleStateRange = (range) =>
    dispatch({
      activeStateRange: {
        startDate: range.startDate,
        endDate: range.endDate
      }
    });

  const toggleRange = (activeDateRange) => {
    const activeStaticDateRange = customStaticRanges.find(
      (item) => item.tag === activeDateRange
    );

    dispatch({
      activeStateRange: {
        startDate: activeStaticDateRange.range().startDate,
        endDate: activeStaticDateRange.range().endDate
      }
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      toggleCoin,
      toggleRange,
      toggleDateRange,
      toggleStateRange
    }),
    [state]
  );

  return <LuckContext.Provider value={value}>{children}</LuckContext.Provider>;
};

export const useLuck = () => {
  const context = useContext(LuckContext);

  if (context === undefined) {
    throw new Error('useLuck must be used within a LuckContextProvider');
  }

  return context;
};
