import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledLuckWrapper = styled.div`
  padding: 40px;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};
  height: 100%;

  @media (max-width: 575px) {
    padding: 20px;
  }
`;
