import { useTranslation } from 'react-i18next';

import Heading from 'components/common/heading/heading';
import CoinToggler from 'components/common/coin-toggler/coin-toggler';
import DataRangePicker from 'components/range-picker/range-picker';
import { useLuck } from 'contexts/luck.context';
import { StyledLuckWrapper } from './luck-statistics-control.style';

const LuckStatisticsControl = () => {
  const { t } = useTranslation();
  const { activeCoin, toggleCoin } = useLuck();

  return (
    <StyledLuckWrapper>
      <Heading mb={4} variant="h3">
        {t('luckStatisticsControl.title')}:
      </Heading>
      {/* <CoinToggler state={[activeCoin, toggleCoin]} width="360px" /> */}
      <DataRangePicker />
    </StyledLuckWrapper>
  );
};

export default LuckStatisticsControl;
