import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const DateRangeStyles = createGlobalStyle`
  div.rdrDefinedRangesWrapper {
    width: 170px;
    border: none;
    background-color: transparent;
  }

  div.rdrStaticRanges {
    > button {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  button.rdrDay {
    color: ${themeGet('colors.text.default')};
  }

  button.rdrStaticRange {
    border: none;
    background-color: transparent;
    color: ${themeGet('colors.text.default')};
    
    span {
      border-radius: ${themeGet('radii.base')};
      transition: all .25s ease;
    }

    &:hover,
    &:focus {
      .rdrStaticRangeLabel {
        background-color: ${themeGet('colors.primary.light')};
      }
    }

  }

  div.rdrCalendarWrapper {
    background-color: transparent;

    @media (max-width: 575px) {
      display: flex;
    }
  }

  div.rdrMonth {
    padding: 0 10px 10px 10px;

    @media (min-width: 576px) {
      width: 20em;
    }
  }

  div.rdrMonthName {
    text-align: center;
    text-transform: capitalize;
    color: ${themeGet('colors.primary.regular')};
  }

  span.rdrWeekDay {
    color: ${themeGet('colors.text.default')};
  }

  span.rdrStartEdge {
    border-radius: ${themeGet('radii.small')};
    background-color: ${themeGet('colors.primary.regular')};
    left: 2px;
    right: 2px;
  }

  span.rdrEndEdge {
    border-radius: ${themeGet('radii.small')};
    background-color: ${themeGet('colors.primary.regular')};
    left: 2px;
    right: 2px;
  }

  button.rdrDayStartOfMonth .rdrInRange,
  button.rdrDayStartOfMonth .rdrEndEdge,
  button.rdrDayStartOfWeek .rdrInRange,
  button.rdrDayStartOfWeek .rdrEndEdge {
    border-radius: ${themeGet('radii.small')};
  }

  span.rdrInRange {
    background-color: transparent;
  }

  button.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, 
  button.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, 
  button.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  button.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: ${themeGet('colors.primary.regular')};
  }

  button.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  button.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: ${themeGet('colors.body.bg')};;
  }


  span.rdrDayNumber {
    span {
      color: ${themeGet('colors.text.default')};
    }
  }

  button.rdrDayDisabled {
    background-color: transparent;
  }

  button.rdrDayPassive span {
    color: transparent;
  }

  span.rdrDayStartPreview {
    border-top-left-radius: ${themeGet('radii.small')};
    border-bottom-left-radius: ${themeGet('radii.small')};
  }

  span.rdrDayEndPreview {
    border-top-right-radius: ${themeGet('radii.small')};
    border-bottom-right-radius: ${themeGet('radii.small')};
  }

  div.rdrMonthAndYearWrapper {
    padding-top: 0;
    height: auto;
    margin-bottom: 10px;
  }

  button.rdrNextPrevButton {
    transition: all .25s ease;
    background-color: ${themeGet('colors.primary.light')};

    &:hover {
      background-color: ${themeGet('colors.primary.light')};
    }
  }

  span.rdrMonthAndYearPickers {

    select {
      transition: all .25s ease;
      color: ${themeGet('colors.text.default')};

      &:hover {
         background-color: ${themeGet('colors.primary.light')};
      }

    }

  }

  button.rdrPprevButton {
    i {
      border-color: transparent ${themeGet(
        'colors.text.default'
      )} transparent transparent;
    }
  }

  button.rdrNextButton {
    i {
      border-color: transparent transparent transparent ${themeGet(
        'colors.text.default'
      )};
    }
  }

`;
