import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_COINS_INFO } from 'graphql/coins.query';

import CoinListItem from 'components/coin-list/coin-list-item';
import { useLuck } from 'contexts/luck.context';
import { Wrapper } from './luck-statisticks.style';
import LuckStatisticsControl from './luck-statistics-control/luck-statistics-control';

const LuckStatistics = () => {
  const { activeCoin, activeDateRange } = useLuck();
  const { data, loading } = useQuery(GET_COINS_INFO);
  const [activeCoinInfo, setActiveCoinInfo] = useState({
    prices: {
      USD: 0
    },
    reward: {
      day: 0,
      active: 0,
      week: 0
    }
  });

  useEffect(() => {
    if (!loading && data?.coinsInfo && activeCoin?.id) {
      setActiveCoinInfo(
        data?.coinsInfo.find((i) => i.coinId === activeCoin?.id)
      );
    }
  }, [activeCoin?.id, data?.coinsInfo, loading]);

  let prop = activeDateRange;

  if (activeDateRange === 'lastWeek') {
    prop = 'week';
  }
  if (activeDateRange === 'lastMonth') {
    prop = 'month';
  }

  return (
    <Wrapper>
      <LuckStatisticsControl />
      <CoinListItem item={activeCoinInfo} prop={prop} />
    </Wrapper>
  );
};

export default LuckStatistics;
