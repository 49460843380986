import { useTranslation } from 'react-i18next';

import { useLuck } from 'contexts/luck.context';
import { StyledButton } from './range-picker.style';

const RangeButton = ({ item: { id, index } }) => {
  const { t } = useTranslation();
  const { activeDateRange, toggleDateRange } = useLuck();
  return (
    <StyledButton
      onClick={() => toggleDateRange(index)}
      active={activeDateRange === index}
    >
      {t(id)}
    </StyledButton>
  );
};

export default RangeButton;
